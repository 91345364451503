.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  background: rgb(191 219 254 / var(1));
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin-top: 1em;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #000;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: rgb(75 85 99 / var(1));
  cursor: pointer;
}